import React, { ReactNode } from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';
import RawHtml from '@components/RawHtml';
import clsx from 'clsx';

type Props = {
  content: ReactNode | string;
  buttonText: ReactNode;
  className?: string;
  containerClassName?: string;
};

function Popover(props: Props) {
  const { content, buttonText, className, containerClassName } = props;

  return (
    <div className={clsx('popover', className, containerClassName)}>
      <PopoverPrimitive.Root>
        <PopoverPrimitive.Trigger
          className={clsx(className, 'popover__trigger')}
          aria-label='Mehr erfahren'
        >
          {buttonText}
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Portal className='popover__portal'>
          <PopoverPrimitive.Content className={clsx(className, 'popover__content')} side='top'>
            {/* <PopoverPrimitive.Close className={clsx(className,'popover__close')}>&times;</PopoverPrimitive.Close> */}
            <PopoverPrimitive.Arrow className={clsx(className, 'popover__arrow')} />
            {typeof content === 'string' ? <RawHtml html={content} /> : content}
          </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
      </PopoverPrimitive.Root>
    </div>
  );
}

export default Popover;
